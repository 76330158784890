import '../App.js';
import ProjectsSection from '../components/Projects/ProjectsSection.js';

function Projects() {
    return (
        <>
            <ProjectsSection />
        </>
    )
}

export default Projects;