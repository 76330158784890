import '../App.js';
import SmashSection from '../components/Smash/SmashSection.js';


function Smash() {
    return (
        <>
            <SmashSection />
        </>
    )
}

export default Smash;