import '../App.js';
import FightstickInfo from '../components/FightStickInfo/FightstickInfo.js';


function Fightstick() {
  return (
    <>
        <FightstickInfo />
    </>
  )
}

export default Fightstick;